import React from "react";
import Layout from "../components/layout/Layout";
import { P } from "../components/general/Typography";
import Button from "../components/general/Button";
import { navigate } from "gatsby";
import Box from "../components/general/Box";

interface Props {}

const NotFound: React.FC<Props> = () => {
  return (
    <Layout>
      <Box m="3">
        <P className="mb-2" light>
          Pagina niet gevonden!
        </P>

        <Button onClick={() => navigate("/")}>Ga naar de homepagina</Button>
      </Box>
    </Layout>
  );
};

export default NotFound;
